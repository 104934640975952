<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="12" :sm="24" :xs="24"
          ><el-row type="flex" justify="start"
            ><h4>Análise de Pedidos</h4></el-row
          >
        </el-col>
        <el-dropdown
          @command="(c) => filterOrders(c)"
          size="medium"
          trigger="click"
        >
          <el-button type="primary" size="medium">
            Exibir por: {{ displayPage || ""
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="Praça">
                <li
                  :class="{
                    'el-dropdown-menu__item': true,
                    'no-padding': true,
                    active: displayPage === 'Praça',
                  }"
                >
                  Praça
                </li>
              </el-dropdown-item>
              <el-dropdown-item command="Licitação"
                ><li
                  :class="{
                    'el-dropdown-menu__item': true,
                    'no-padding': true,
                    active: displayPage === 'Licitação',
                  }"
                >
                  Licitação
                </li></el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-row>
    </template>
    <el-empty
      v-if="!Object.keys(GroupedOrders)?.length"
      description="Nenhum pedido nesta lista"
    ></el-empty>

    <div v-for="(orders, route) in GroupedOrders" :key="route">
      <h2 class="el-table__subheader_name">{{ route }}</h2>

      <el-table
        stripe
        :cell-style="() => 'text-align:center;'"
        :data="orders"
        style="width: 100%; z-index: 0"
        :row-style="() => 'cursor:pointer;'"
        @row-click="(r) => openOrderModal(r)"
      >
        <el-table-column label="#" prop="code" width="100"></el-table-column>
        <el-table-column
          label="romaneio"
          prop="invoice_id"
          width="100"
        ></el-table-column>
        <el-table-column prop="seller.name" label="vendedor"> </el-table-column>
        <el-table-column
          prop="client.legal.company_name"
          label="razao social"
        ></el-table-column>
        <el-table-column label="cliente" prop="client.name">
          <template #default="{ row: order }">
            {{ order.client?.legal?.social_name || order.client?.name }}
          </template>
        </el-table-column>
        <el-table-column
          label="data pedido"
          width="100"
          :formatter="
            (r) => dateFormatter.format(new Date(r.created_at || Date.now()))
          "
        >
        </el-table-column>
        <el-table-column
          label="data entrega"
          width="100"
          :formatter="
            (r) => dateFormatter.format(new Date(r.deliver_at) || Date.now())
          "
        >
        </el-table-column>
        <el-table-column label="estado" prop="status_formatted" width="100">
          <template #default="s">
            <el-tag :type="getStatusType(s.row)">{{
              s.row.status_formatted
            }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <order-modal
      :order="order"
      :showModal="showOrderModal"
      @should-update="fetchOrders"
      @close-modal="showOrderModal = false"
    ></order-modal>
  </el-card>
</template>

<script>
//import { ElNotification } from "element-plus";
import OrderModal from "./modals/OrderModal.vue";
import OrderService from "../services/orders";
import { dateFormatter, currencyFormatter } from "../utils/formatters";
export default {
  name: "OrdersAnalisysPage",
  components: { OrderModal },
  data: () => ({
    hasError: false,
    order: {},
    showOrderModal: false,
    isLoading: true,
    orders: null,
    currencyFormatter,
    displayPage: "Praça",
    dateFormatter,
    fetchRepeater: null,
  }),
  mounted() {
    this.fetchOrders();
    this.fetchRepeater = setInterval(() => this.fetchOrders(false), 5000);
  },
  unmounted() {
    clearInterval(this.fetchRepeater);
  },
  watch: {},
  computed: {
    Orders() {
      return this.orders || [];
    },
    GroupedOrders() {
      return this.groupByPreviousStatus();
    },
    OrderStats() {
      if (this.Orders?.length) {
        const delayed = this.Orders.filter((o) => this.isDelayed(o))?.length;
        const on_time = this.Orders.length - delayed;
        const producing = this.Orders.filter(
          (o) => o.status === "production"
        )?.length;
        const last_day = this.Orders.filter(
          (o) => !this.isDelayed(o) && this.hoursLeft(o.deliver_at) > 18
        )?.length;

        return {
          delayed,
          on_time,
          producing,
          last_day,
          total: this.Orders.length,
        };
      }

      return null;
    },
  },
  methods: {
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    hoursLeft(time) {
      return (new Date(time) - new Date()) / 36e5;
    },
    openOrderModal(order) {
      this.order = order;
      this.showOrderModal = true;
    },
    groupByPreviousStatus() {
      return (
        this.orders?.reduce((t, o) => {
          const status = o.next_status || "created";
          if (t[ORDER_STATUS_LABELS[status]])
            t[ORDER_STATUS_LABELS[status]].push(o);
          else t[ORDER_STATUS_LABELS[status]] = [o];

          return t;
        }, {}) || {}
      );
    },
    isDelayed(order) {
      return order.deliver_at < new Date().toISOString();
    },
    getStatusType(order) {
      if (
        this.isDelayed(order) &&
        ["created", "accepted", "production"].includes(order.status)
      )
        order.status = "delayed";
      return ORDER_STATUS_TYPES[order.status];
    },
    filterOrders(filter) {
      this.displayPage = filter;
      this.fetchOrders();
    },
    async fetchOrders(showLoading = true) {
      if (showLoading) this.isLoading = true;
      const { orders } = await OrderService().index({
        status: "analisys",
        isPublicSell: this.displayPage === "Licitação",
      });

      if (orders) this.orders = orders;

      this.isLoading = false;
    },
  },
};
const ORDER_STATUS_TYPES = {
  created: "info",
  production: null,
  analisys: "warning",
  accepted: null,
  ready: null,
  delayed: "danger",
  delivered: "success",
  completed: "success",
};
const ORDER_STATUS_LABELS = {
  created: "NOVOS PEDIDOS",
  production: "EM PRODUÇÃO",
  analisys: "EM ANÁLISE",
  accepted: "APROVADO",
  ready: "PRODUÇÃO CONCLUÍDA",
  delayed: "EM ATRASO",
  delivered: "ENTREGUE",
  completed: "FINALIZADO",
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>
